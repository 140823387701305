<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent
        :title="$t.getTranslation('LBL_EDIT_PROFILE')"
        type="back"
        :settings="false"
      />
    </template>

    <div class="settingsinput-container">
      <div class="container">
        <f7-list no-hairlines>
          <f7-list-item divider>{{
            $t.getTranslation("LBL_ACCOUNT_INFORMATION")
          }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.UserName"
            name="UserName"
            :required="validationRules?.UserName?.required"
            :minlength="validationRules?.UserName?.minimumLength"
            :maxlength="validationRules?.UserName?.maximumLength"
            :label="$t.getTranslation('LBL_USERNAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="text"
            validate
            clear-button
            @keydown.space.prevent
            v-on:keyup="checkUserName"
          >
          </f7-list-input>

          <f7-list-item divider v-if="!isUserNamePass">
            <p
              style="font-size: 10px; color: #ff0000; white-space: pre-line; margin: 0px"
            >
              * {{ $t.getTranslation("LBL_USERNAME_NOTE") }}
            </p>
          </f7-list-item>

          <f7-list-input
            v-model:value="formData.Email"
            name="Email"
            :required="validationRules?.Email?.required"
            :minlength="validationRules?.Email?.minimumLength"
            :maxlength="validationRules?.Email?.maximumLength"
            :label="$t.getTranslation('LBL_EMAIL')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="email"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-item divider>{{
            $t.getTranslation("LBL_PRIMARY_INFORMATION")
          }}</f7-list-item>
          <f7-list-input
            v-model:value="formData.FirstName"
            name="FirstName"
            :required="validationRules?.FirstName?.required"
            :minlength="validationRules?.FirstName?.minimumLength"
            :maxlength="validationRules?.FirstName?.maximumLength"
            :label="$t.getTranslation('LBL_FIRST_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="text"
            validate
            clear-button
            @change="removeExtraSpaces('FirstName')"
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.LastName"
            name="LastName"
            :required="validationRules?.LastName?.required"
            :minlength="validationRules?.LastName?.minimumLength"
            :maxlength="validationRules?.LastName?.maximumLength"
            :label="$t.getTranslation('LBL_LAST_NAME')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="text"
            validate
            clear-button
            @change="removeExtraSpaces('LastName')"
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.BirthDate"
            name="BirthDate"
            :min="minimumBirthDate"
            :max="maximumBirthDate"
            :required="validationRules?.BirthDate?.required"
            :minlength="validationRules?.BirthDate?.minimumLength"
            :maxlength="validationRules?.BirthDate?.maximumLength"
            :label="$t.getTranslation('LBL_BIRTH_DATE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="date"
            placeholder="YYYY/MM/DD"
            validate
          >
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.Gender"
            name="Gender"
            :required="validationRules?.Gender?.required"
            :minlength="validationRules?.Gender?.minimumLength"
            :maxlength="validationRules?.Gender?.maximumLength"
            :label="$t.getTranslation('LBL_GENDER')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="select"
            validate
          >
            <template v-if="genderList && genderList.length > 0">
              <option
                v-for="gender in genderList"
                :key="'mc_' + gender.value"
                :value="gender.value"
              >
                {{ gender.name }}
              </option>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.Bio"
            name="Bio"
            :required="validationRules?.Bio?.required"
            :minlength="validationRules?.Bio?.minimumLength"
            :maxlength="validationRules?.Bio?.maximumLength"
            :label="$t.getTranslation('LBL_BIO')"
            :placeholder="$t.getTranslation('LBL_TYPE_HERE')"
            :error-message="
              $t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''
            "
            floating-label
            outline
            type="textarea"
            validate
            clear-button
          >
          </f7-list-input>
          <f7-list-item>
            <f7-button
              fill
              large
              preloader
              :loading="isButtonProcessing"
              :disabled="isButtonProcessing || isDisableNextButton"
              @click="next"
            >
              {{ $t.getTranslation("LBL_UPDATE") }}
            </f7-button>
          </f7-list-item>
        </f7-list>
      </div>
    </div>
  </f7-page>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  inject,
  computed,
  onMounted,
  defineAsyncComponent,
} from "vue";
import dayjs from "dayjs";

import { useStore } from "@/store";
import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { $HTTP } from "@/utils/axios";
import _ from "lodash";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"
  )
);

export default defineComponent({
  name: "SettingsAccountPage",
  components: {
    ProfileNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();
    const isUserNamePass = ref(true);

    store.dispatch("config/fetchData", { params: { genderList: 1 } });
    const genderList = computed(() => store.getters["config/getData"]?.genderList);

    const userInfo = computed(() => store.getters["user/getData"]);
    const isButtonProcessing = ref(false);

    const formData = reactive({
      UserName: "",
      Email: "",
      FirstName: "",
      LastName: "",
      BirthDate: "",
      Gender: "",
      Bio: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    onMounted(() => {
      getProfileInfo();
    });

    const getProfileInfo = async () => {
      let userInfo = await get("/user/info");
      for (let keys in formData) {
        if (!helpers.isBlank(userInfo[keys])) {
          if (keys.indexOf("Date") > -1) {
            formData[keys] = dayjs(userInfo[keys]).format("YYYY-MM-DD");
          } else {
            formData[keys] = userInfo[keys];
          }
        }
      }

      await checkUserName();
    };

    const checkUserName = _.debounce(async () => {
      if (!(formData.UserName == "") && formData.UserName.length >= 4) {
        try {
          let res = await $HTTP.post("/user/username/check", {
            UserName: formData.UserName,
            UserKey: userInfo.value?.UserKey || null,
          });
          isUserNamePass.value = true;
        } catch (err) {
          isUserNamePass.value = false;
          helpers.catchError(err, true);
        }
      }

      if (formData.UserName.length < 4 || formData.UserName == "") {
        isUserNamePass.value = false;
      }

      return;
    }, 300);

    const minimumBirthDate = computed(() => {
      return dayjs().subtract(60, "year").format("YYYY-MM-DD");
    });

    const maximumBirthDate = computed(() => {
      return dayjs().subtract(18, "year").format("YYYY-MM-DD");
    });

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const next = async () => {
      let isValid = validate(true);

      if (isValid) {
        let ret = await post("/user/update/primary", formData);
        if (ret) {
          helpers.createNotification({
            type: "info",
            title: $t.getTranslation("LBL_INFO"),
            message: $t.getTranslation("LBL_INFO_SUCCESS_ACCOUNT_UPDATE"),
          });
          props.f7router.back({ force: true });
        }
      }
    };

    const removeExtraSpaces = (field) => {
      if (formData[field]) {
        formData[field] = formData[field].toString().replace(/\s+/g, " ").trim();
      }
    };

    return {
      checkUserName,
      removeExtraSpaces,
      isUserNamePass,
      genderList,
      formData,
      validationRules,
      minimumBirthDate,
      maximumBirthDate,
      isButtonProcessing,
      isDisableNextButton,
      next,
    };
  },
});
</script>

<style>
.settingsinput-container .container {
  padding-top: 0px;
}
</style>
