let validationRules = {
  UserName: {
    required: true,
    minimumLength: 4,
    maximumLength: 20,
    pattern: {
      value: '^[a-zA-Z0-9\u3400-\u9FBF]+$',
      message: 'Error! UserName contains invalid characters, please use only alpha numeric characters like A-Z or 0-9.'
    },
    invalid: ['main', 'admin', 'www', 'contact', 'info', 'app', 'server']
  },
  Password: {
    required: true,
    minimumLength: 6,
    maximumLength: 20,
    pattern: false
  },
  ConfirmPassword: {
    required: true,
    minimumLength: 6,
    maximumLength: 20,
    pattern: false
  },
  CurrentPassword: {
    required: true,
    minimumLength: 6,
    maximumLength: 20,
    pattern: false
  },
  NewPassword: {
    required: true,
    minimumLength: 6,
    maximumLength: 20,
    pattern: false
  },
  Email: {
    required: false,
    minimumLength: 2,
    maximumLength: 255,
    pattern: {
      value: '^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
      message: 'Error! Invalid email address format.'
    }
  },
  FirstName: {
    required: true,
    minimumLength: 1,
    maximumLength: 25,
    pattern: false
  },
  LastName: {
    required: true,
    minimumLength: 1,
    maximumLength: 25,
    pattern: false
  },
  MobileCode: {
    required: true,
    minimumLength: 2,
    maximumLength: 5,
    pattern: false
  },
  MobileNumber: {
    required: true,
    minimumLength: 4,
    maximumLength: 15,
    pattern: false
  },
  VerificationCode: {
    required: true,
    minimumLength: 4,
    maximumLength: 4,
    pattern: false
  },
  BirthDate: {
    required: false
  },
  PostTitle: {
    required: false,
    minimumLength: 2,
    maximumLength: 1000,
    pattern: false
  },
  PostText: {
    required: false,
    minimumLength: 2,
    maximumLength: 500,
    pattern: false
  },
  Comment: {
    required: true,
    minimumLength: 2,
    maximumLength: 500,
    pattern: false
  },
  ReportType: {
    required: true,
    minimumLength: 2,
    maximumLength: 500,
    pattern: false
  },
  ReportContent: {
    required: true,
    minimumLength: 2,
    maximumLength: 500,
    pattern: false
  }
}

export default validationRules
